<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Edit {{ customer.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-row>
          <v-col class="col-10">
            <v-text-field
              :rules="[rules.required]"
              hint="Required"
              persistent-hint
              v-model="customer.name"
              label="Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              :rules="[rules.required]"
              hint="Required"
              persistent-hint
              v-model="customer.address_1"
              label="Address One"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.address_2"
              label="Address Two"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.address_3"
              label="Address Three"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.address_4"
              label="Address Four"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.address_5"
              label="Address Five"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
               :rules="[rules.required]"
               hint="Required"
               persistent-hint
              v-model="customer.postcode"
              label="Postcode"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.tel"
              label="Telephone"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.email"
              label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-textarea
              v-model="customer.notes"
              rows="3"
              label="Notes"
              hint="These will show when adding a new quotation, i.e. Customer requires a PO"
              persistent-hint></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-6">
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.sage_ref"
              label="Sage reference"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-autocomplete
              label="Business Type"
              v-model="customer.business_type_id"
              :items="businessTypes"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              label="Credit Limit"
              type="number"
              step="0.01"
              v-model="customer.credit_limit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-select
              label="Payment terms"
              :items="paymentTerms"
              item-text="text"
              item-value="value"
              v-model="customer.payment_terms_id"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-checkbox
              label="Account cleared to order"
              v-model="customer.cleared"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-checkbox
              label="Application for payment"
              v-model="customer.i_application"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-checkbox
              label="Purchase order required"
              v-model="customer.po_required"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-checkbox
              label="Block account use"
              v-model="customer.blocked"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="userPermissions.includes(11)">
          <v-col class="col-10">
            <v-checkbox
              label="Send Order Acknowledgement Email"
              v-model="customer.send_oa_email"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.vat_registration_no"
              label="VAT Registration number"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-text-field
              v-model="customer.accounts_email"
              label="Accounts Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10">
            <v-checkbox
              label="Archive customer"
              v-model="customer.archived"></v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-btn
          @click="saveChanges">Save changes</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link
            :to="`/customers/view/${customer.id}`">
            <v-btn
            color="orange darken-2"
            text>
              OK
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'EditCustomer',
  data() {
    return {
      customer: {
        name: '',
        business_type_id: 0,
        address_1: '',
        postcode: '',
      },
      paymentTerms: [],
      businessTypes: [],
      message: '',
      showMessage: false,
      rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
      },
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  methods: {
    saveChanges() {
      if (
        this.customer.name.length > 0
        && this.customer.address_1.length > 0
        && this.customer.postcode.length > 0
        ) {
        const customerId = this.$route.params.id;
        axios.post(`/customers/update/${customerId}.json?token=${this.token}`, this.customer)
          .then((response) => {
            if (response.data.postedToSage === true) {
              this.message = 'The customer updates have been posted to Sage';
            } else {
              this.message = 'The customer updates have not posted to Sage';
            }
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.message = 'Please complete all required fields: Name, Address One and Postcode';
        this.showMessage = true;
      }
    },
    getCustomer() {
        const customerId = this.$route.params.id;
        axios.get(`/customers/getSingle/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.customer.credit_limit = (this.customer.credit_limit / 100);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBusinessTypes() {
      axios.get(`/businessTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.businessTypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPaymentTerms() {
      axios.get(`/paymentTerms/getAll.json?token=${this.token}`)
        .then((response) => {
          this.paymentTerms = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCustomer();
    this.getBusinessTypes();
    this.getPaymentTerms();
  },
}
</script>
